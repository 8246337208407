<template>
    <div class="flex justify-center">
        <div style="width: 95%;">
            <div class="flex items-center mt-5">
                <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" @click="goBack" />
                <p class="text-xl ml-4 font-bold" style="color: #ff8e3b">EAE Course Purchase</p>
            </div>
            <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
                <div class="flex justify-between font-bold text-lg">
                    <p>Course</p>
                    <p style=" color: var(--c-primary);">{{ applicationInfo.classInfo.class_info.class_name }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p>Standard Fee</p>
                    <p style="font-weight: bold;color: #ff8e3b;">{{ currency }} {{ standard_fee
                        }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p>Applicable Fee Reduction</p>
                    <p style="font-weight: bold;color: #ff8e3b;">{{ currency }} {{ calcReductionFee() }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p> Fee After Reduction</p>
                    <p style="font-weight: bold;color: #ff8e3b;">{{ currency }} {{ applicationInfo.total_price <= 0 ? 0 :
                        applicationInfo.total_price }}</p>
                </div>
                <div class="flex justify-between text-lg my-4">
                    <p>Payment Service Fee</p>
                    <p style="font-weight: bold;color: #ff8e3b">{{ currency }} {{ serviceFee }}</p>
                </div>

                <div style="width: 100%;height: 1px;background-color: var(--c-primary);"> </div>

                <div class="flex justify-between text-lg my-4 font-bold">
                    <p>Total Order Amount</p>
                    <p style="color: #ff8e3b">{{ currency }} {{ amount }}</p>
                </div>
                <p style="font-style: italic;">*The service fee is charged by the payment platform. It is not
                    included in the registration fee.</p>
            </div>
            <div class="pay-info" style="width: 100%;padding: 15px 20px;box-sizing: border-box;margin-top: 25px;">
                <p class="text-xl font-bold">Terms and Conditions</p>
                <p class="ql-snow ql-editor" style="margin-top: 10px;line-height: 24px;font-size: 16px;">
                    Courses and cases offered on our platform are virtual products. As such, once payment is completed,
                    we do not offer refunds under any circumstances.
                    <br>
                    <br>
                    Policy Details:
                    No Refunds: Due to the nature of our digital products, all sales are final. Once you have paid for a
                    course, you will not be able to request a refund or cancellation.

                    Access to Content: Upon successful payment, you will receive immediate access to the learning
                    materials. This access is considered the full delivery of our service.
                </p>
            </div>
            <div class="terms flex items-center justify-center" style="margin-top: 20px;">
                <div @click="isAgree = !isAgree" style="display: flex; align-items: center">
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-if="!isAgree"
                        :src="require('../../assets/image/unchecked.png')" />
                    <img style="width: 16px;height: 16px;cursor: pointer;margin-right: 16px;" v-else
                        :src="require('../../assets/image/checked.png')" />
                </div>
                <p> I have read and agree on the terms and conditions.</p>
            </div>
            <div class="w-full flex justify-center items-center" style="margin: 30px 0px;">
                <el-button class="btn" @click="goBack">Cancel</el-button>
                <el-button class="btn" type="primary" @click="goPay">Pay</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    checkClassApplicationById,
} from '../../api/eae'
export default {
    name: "Payment",
    data() {
        return {
            application_id: this.$route.query.application_id,
            isAgree: false,
            applicationInfo: {},
            start_date: '',
            end_date: '',
            amount: 0,
            serviceFee: 0.00,
            standard_fee: 0,
            currency: 'USD'
        };
    },
    mounted() {
        this.fetchData()
    },

    methods: {

        fetchData() {
            if (true) {
                checkClassApplicationById(this.application_id).then((res) => {
                    this.applicationInfo = res.data.data
                    this.currency = this.applicationInfo.price_currency
                    this.serviceFee = this.applicationInfo.service_fee
                    this.standard_fee = this.applicationInfo.standard_fee
                    this.amount = this.applicationInfo.service_fee + this.applicationInfo.total_price
                    if (this.amount <= 0) {
                        this.amount = 0
                    }
                })
                return
            }
        },
        calcReductionFee() {
            let fee = this.standard_fee - (this.applicationInfo.total_price)
            return '(' + fee + ')'
        },
        goBack() {
            this.$router.go(-1)
        },
        goPay() {

            if (!this.isAgree) {
                this.$message.warning("Please read terms and agree it");
                return;
            }
            if (this.applicationInfo.status != "待缴费") {
                this.$message.success('Pay success~')
                this.goBack()
                return
            }
            let url = this.$router.resolve({
                path: "/official_course-pay",
                query: {
                    application_id: this.application_id,
                },
            });
            window.open(url.href, "_blank");
        }
    }
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

.btn {
    width: 160px;
}

.terms {
    margin-top: 10px;
    width: 100%;
    background-color: #F4F4F4;
    padding: 20px 30px;
    box-sizing: border-box;
    color: #666;
    font-size: 16px;
    line-height: 24px;
}

.btn {
    width: 160px;
}

.pay-info {
    border-radius: 12px;
    background-color: #FFF8F8;
    color: #333;
    border: 1px solid #DEDEDE;
    padding: 20px 40px;
    box-sizing: border-box;
    margin-top: 30px;
}
</style>